export default {
  "palette": {
    "popup": {
      "background": "#383b75"
    },
    "button": {
      "background": "#f1d600"
    }
  },
  position: 'top-right',
  content: {
    message: 'Portal korzysta z technologii Ciasteczek. Korzystając z portalu zgadzasz się na użycie tej technologii.',
    dismiss: 'Rozumiem',
    link: 'Polityka prywatności',
    href: '/docs/polityka_prywatnosci.pdf'
  }
}
