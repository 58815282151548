// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
// import * as ActiveStorage from "@rails/activestorage"

// Rails.start()
// ActiveStorage.start()

import 'bulma/css/bulma.css'
import '../css/common.scss'
import '../css/frontend.scss'

const images = require.context("../images", true)
const imagePath = name => images(name, true)

import '../js/cookie'
